.wrap-header {
  border-bottom: 1px solid #e5e5e5;
}
.header {
  padding: 20px 0px 0px 0px;
  margin-bottom: 8px;
}
.header .logo {
  width: 160px;
  margin-top: -14px;
}
.header .search input {
  border-color: #cce5ed;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  line-height: 25px;
  border-right: none;
}
.header .search input:focus,
.header .search input:hover {
  border-color: #cce5ed;
  box-shadow: none;
}
.header .search button {
  border-color: #cce5ed;
  border-radius: 0px !important;
  border-left: 0px;
  border-top-right-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
  height: 35px;
}
.header .search button .anticon {
  font-size: 18px;
}
.header .hotline {
  color: var(--c-gray);
}
.header .hotline a {
  color: #004972;
}
.header .btn-cart {
  padding-left: 20px;
  color: #777;
  background: none !important;
  position: relative;
}
.header .btn-cart:hover,
.header .btn-cart:hover .icon-cart {
  color: var(--c-hover);
}
.header .btn-cart .icon-cart {
  font-size: 22px;
  color: #777;
  position: absolute;
  left: -5px;
  top: -3px;
}
.header .btn-cart .badge {
  background-color: var(--c-primary);
  color: #fff;
  padding: 2px 7px;
  font-size: 9px;
  border-radius: 10px;
  margin-left: 5px;
}
.header .user {
  background: #f0f0f0;
  padding: 5px 10px 5px 5px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.header .user .ant-avatar{width: 32px;height: 32px;}
.header .user .name {
  color: #004972;
  font-size: 14px;
  margin-left: 8px;
  line-height: 30px;
  max-width: 100px;
}
.header .user .icon-drop {
  color: #999;
  font-size: 13px;
  margin-left: 5px;
  margin-top: 3px;
}
.header .language{border-radius: 20px; padding:4px 8px}
.header .language svg{width: 11px; height: 11px;}
.header .icon-flag{width: 16px; margin: -3px 3px 0px 0px}
.icon-flag-menu{width: 16px; margin: -3px 5px 0px 0px}
.header .language.mobile{padding: 0px 3px; height: auto; font-size: 13px;}
.header .mobile .icon-flag{width: 16px; margin: -1px 2px 0px 0px}
/* Menu */
.menu {
  justify-content: space-between;
}
.menu.lang {
  justify-content: unset
}
.menu .ant-menu {
  border-bottom: 0px;
}
.menu .icon-drop {
  font-size: 10px;
}
.menu a {
  font-size: 15px;
  color: #333;
  font-weight: 500;
}
.menu.lang a{font-size: 14px;}
.menu a:hover {
  color: var(--c-hover);
}
.menu .lv1 {
  padding: 0px 5px !important;
}
.menu .lv1:first-child {
  padding-left: 0px !important;
}
.menu .lv1:first-child::after {
  left: 0px !important;
}
.menu .ant-menu-horizontal > .ant-menu-item::after,
.ant-menu-horizontal > .ant-menu-submenu::after {
  right: 10px;
  left: 4px;
}
.menu .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
.menu .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
.menu .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
.menu
  .ant-menu-horizontal:not(.ant-menu-dark)
  > .ant-menu-submenu-active::after,
.menu .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
.menu .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
.menu .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
.menu
  .ant-menu-horizontal:not(.ant-menu-dark)
  > .ant-menu-submenu-selected::after {
  border-bottom: 2px solid var(--c-hover);
}
.menu .ant-menu-horizontal > .ant-menu-item-selected a {
  color: var(--c-hover);
}
.menu .ant-menu-horizontal > .ant-menu-item a:hover {
  color: var(--c-hover);
}
.menu-poup .ant-menu-item a:hover {
  color: var(--c-hover);
}
.menu-poup .ant-menu-item a {
  font-size: 15px;
}
.menu-poup .ant-menu-submenu-title,
.menu-poup .ant-menu-title-content {
  height: 35px !important;
  line-height: 35px !important;
}
.menu-poup a {
  color: #333 !important;
  font-size: 15px;
}
.menu-poup a:hover {
  color: var(--c-hover) !important;
}
.menu-poup .ant-menu-item-only-child {
  height: 35px;
  line-height: 35px;
}

/* Footer */
footer {
  background: var(--c-primary);
  padding: 30px 0px;
  color: #e5e5e5;
}
footer .logo {
  width: 150px;
  margin-bottom: 10px;
}
footer .col-info {
  line-height: 30px;
}
footer .col-info .company-name {
  font-weight: 500;
  margin-bottom: 20px;
  margin-top: 8px;
}
footer .list-menu .ant-list-header {
  font-weight: 700;
  color: #fff;
  border-bottom: none;
  margin-bottom: 7px;
}
footer .list-menu .ant-list-item {
  border-bottom: none;
  padding: 5px 0px;
}
footer .list-menu .ant-list-item a {
  color: #e5e5e5;
}
footer .list-menu .ant-list-item a:hover {
  color: #1890ff;
}
footer .list-menu .ant-col .ant-list-item {
  margin-bottom: 5px;
  margin-top: 6px;
}
footer .support .icon-phone {
  width: 45px;
}
footer .support .hotline {
  color: #fff;
  margin-top: 20px;
}
footer .support .hotline .phone a {
  font-size: 21px;
  font-weight: 700;
  color: #fff;
}
footer .support .hotline .phone:hover a {
  font-size: 21px;
  font-weight: 700;
  color: var(--c-hover);
}
footer .support .connect {
  font-weight: 700;
  color: #fff;
  margin-bottom: 7px;
  margin-top: 30px;
}
footer .support .connect-icon {
  margin-top: 10px;
}
footer .support .connect-icon i {
  color: #fff;
}
footer .support .connect-icon .ant-avatar-icon {
  margin-right: 10px;
  background: #145187;
}
footer .support .connect-icon .ant-avatar-icon:hover {
  background: var(--c-hover);
}
