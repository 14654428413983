.page-travel-medical {padding-bottom: 30px;}
.page-travel-medical .emergency-content{max-width: 80%; margin:0 auto;}
.page-travel-medical .emergency-content p, .page-travel-medical .emergency-content div{text-align: justify; line-height: 32px;}
.page-travel-medical .emergency-content .step{color: #00a3c8; font-weight: 700; font-size: 16px;}
.page-travel-medical .emergency-content .hotline{font-size: 20px; color: #00a3c8; font-weight: 500;}
.page-travel-medical .emergency-content .group-hotline{margin: 24px 0px;}
.page-travel-medical .mental-content{max-width: 80%; margin: 0 auto;}
.page-travel-medical .mental-content .ant-row{margin-bottom: 36px;}
.page-travel-medical .mental-content .title{font-size: 22px; font-weight: 700; color: #333; text-transform: uppercase; margin-bottom: 36px;}
.page-travel-medical .mental-content .title.mt{margin-top: 36px;}
.page-travel-medical .mental-content img{width: 100%; border-radius:20px ;}
.page-travel-medical .mental-content .sapo{line-height: 26px;}
.page-travel-medical .mental-content .btn-view{padding: 12px 36px; text-transform: uppercase; font-weight: 500;}