.menu-left-service {
  background: #f0f0f0;
  border-radius: 10px;
  padding-bottom: 20px;
}
.menu-left-service .category-header {
  text-transform: none;
  font-weight: 500;
  font-size: 18px;
  color: #333;
  border: none;
  margin-bottom: 10px;
  padding: 20px 10px 0px 10px;
  margin-top: 0px;
}
.menu-left-service .category {
  background: none;
  padding: 0px 10px
}
.menu-left-service .category .ant-menu-item {
  height: auto;
  line-height: 22px;
  align-items: baseline;
}
.menu-left-service .category .ant-menu-item .ant-menu-item-icon {
  font-size: 12px;
}
.menu-left-service .ant-menu-title-content {
  white-space: normal;
}
.menu-left-service .category .ant-menu-title-content{line-height: 18px;}
.menu-left-service .category .ant-menu-title-content a{font-size: 14px;}
.menu-left-service .category .ant-menu-item.lv3{padding-left: 18px!important;}

.service-detail .select-time {
  margin: 20px 0px;
}
.service-detail .input-date {
  border-radius: 20px;
  width: 100%;
}
.service-detail .col-gallery .image-gallery-slides {
  border: 1px solid #f1f1f1;
}
.service-detail .product-content {
  border-top: none;
  margin: 10px 0px;
}
.service-detail .block-review {
  margin-top: 40px;
}
.service-detail .image-gallery-thumbnail {
  overflow: hidden;
}
.service-complete .payment-success .box-info {
  line-height: 40px;
}
.item-service img{min-height: auto; max-height: 193px;}
.service-detail .group-social{display: flex; align-items: center;}
.service-detail .group-social .zalo-share-button{margin-top:8px; margin-right:20px}
