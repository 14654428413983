.page-qa .list-qa {
  padding-bottom: 30px;
}
.page-qa .list-qa {
  max-width: 900px;
  margin: 0 auto;
}
.page-qa .list-qa .item {
  margin-bottom: 16px;
}
.page-qa .list-qa .item .title {
  font-weight: 500;
  color: var(--c-hover);
}
