.page-facility .col-search .box-search {
  background: var(--c-primary);
  border-radius: 10px;
  padding: 20px;
}
.page-facility .col-search .box-search .title {
  font-size: 22px;
  font-weight: 700;
  color: #fff;
}
.page-facility .col-search .box-search .form-search {
  margin-top: 10px;
}
.page-facility .col-search .box-search .btn-search {
  width: 70%;
}

.list-facility {
  margin-bottom: 30px;
}

.facility {
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 30px;
  box-shadow: 3px 1px 5px 0px #ccc;
}
.facility .box-cover {
  overflow: hidden;
  width: 100%;
}
.facility .box-cover img {
  width: 100%;
  min-height: 255px;
}
.facility .col-info {
  padding-right: 30px !important;
}
.facility .title {
  font-size: 19px;
  color: #333;
  font-weight: 500;
  margin: 10px 0px;
}
.facility .specialty {
  margin: 0px 0px 15px 0px;
}
.facility .location {
  font-size: 15px;
  color: #555;
  padding-left: 30px;
  position: relative;
  margin-bottom: 5px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 22px;
  height: 45px;
  overflow: hidden;
}
.facility .location .anticon {
  color: var(--c-hover);
  margin-right: 10px;
  position: absolute;
  left: 0;
  top: 2px;
  font-size: 18px;
}
.facility .sapo {
  color: #555;
  font-size: 15px;
  margin-top: 10px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 20px;
  height: 40px;
  overflow: hidden;
}
.facility .control-btn {
  margin-top: 25px;
}
.facility .btn-view-detail {
  font-weight: 500;
  font-size: 16px;
  padding: 10px 50px;
}

/* facility detail */
.facility-detail .breadcrumb {
  margin-bottom: 30px;
}
.facility-detail .contact {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
}
.facility-detail .contact .label {
  margin-right: 40px;
  font-weight: 500;
}
.facility-detail .contact .facebook {
  margin-left: 5px;
  cursor: pointer;
}
.facility-detail .contact .facebook img {
  width: 32px;
}
.facility-detail .contact .messenger {
  margin-left: 10px;
  cursor: pointer;
}
.facility-detail .contact .messenger img {
  width: 32px;
}
.facility-detail .other-info {
  font-size: 15px;
  color: #555;
  position: relative;
  margin-top: 20px;
  line-height: 30px;
}
.facility-detail .location ion-icon {
  color: var(--c-hover);
  margin-right: 10px;
  position: absolute;
  left: 0;
  top: 2px;
  font-size: 18px;
}
.facility-detail .btn-book {
  width: 300px;
  margin-top: 30px;
  height: 40px;
  font-size: 16px;
  font-weight: 500;
}
.facility-detail .btn-view-map {
  margin-left: -15px;
}
.facility-detail .post-by {
  margin-top: 30px;
}
/* facility tab info */
.facility-detail .tabs-info {
  margin-top: 40px;
}
.facility-detail .tabs-info .ant-tabs-nav {
  margin-bottom: 0px;
}
.facility-detail .tabs-info .ant-tabs-nav::before {
  border: none;
}
.facility-detail .tabs-info .ant-tabs-content-holder {
  border-top: 1px solid var(--c-btn-yellow);
  padding: 30px 0px;
  font-size: 15px;
}
.facility-detail .tabs-info .ant-tabs-tab {
  background: none;
  border: none;
  height: 60px;
  font-weight: 500;
}
.facility-detail .tabs-info .ant-tabs-tab .ant-tabs-tab-btn {
  font-size: 20px;
  color: #999;
  padding: 0px 30px;
}
.facility-detail .tabs-info .ant-tabs-tab.ant-tabs-tab-active {
  background: var(--c-btn-yellow);
}
.facility-detail
  .tabs-info
  .ant-tabs-tab.ant-tabs-tab-active
  .ant-tabs-tab-btn {
  color: #fff;
}

.facility-detail .service-specialty .ant-collapse-header {
  background: none;
  padding-left: 0px;
  padding-right: 0px;
  font-size: 16px;
  font-weight: 700;
  text-transform: capitalize;
  color: var(--c-primary);
}
.facility-detail .service-specialty .header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
}
.facility-detail .service-specialty .header .title {
  font-size: 22px;
  color: var(--c-primary);
  margin-left: 20px;
  font-weight: 500;
  text-transform: capitalize;
}
.facility-detail .service-specialty .ant-collapse {
  background: transparent;
}
.facility-detail .service-specialty .item-doctor {
  margin-bottom: 30px;
}
.facility-detail .service-specialty .item-doctor .box-cover {
  width: 100%;
  height: 238px;
  overflow: hidden;
  box-shadow: 1px 2px 4px 1px #ccc;
}
.facility-detail .service-specialty .item-doctor .box-cover img {
  width: 100%;
  min-height: 238px;
}
.facility-detail .service-specialty .list-doctor .label {
  font-size: 20px;
  font-weight: 700;
  margin: 30px 0px 20px 0px;
}
.facility-detail .service-specialty .doctor-name {
  font-size: 18px;
  font-weight: 500;
}
.facility-detail .service-specialty .position {
  color: #777;
  font-size: 16px;
  margin: 10px 0px 20px 0px;
}
.facility-detail .specialty-collapse .summary {
  margin: 10px 0px 20px 0px;
}
.facility-detail .specialty-collapse .ant-empty {
  margin-bottom: 16px;
}
.facility-detail .service-specialty .sapo {
  color: #555;
  font-size: 14px;
}
.facility-detail .specialty-collapse {
  border: 0px;
}
.facility-detail .specialty-collapse .ant-avatar {
  margin-top: -5px;
  margin-right: 10px;
}
.facility-detail .ant-collapse-content {
  border-top: 0px;
}
.facility-detail .doctor-detail .tabs-detail .service .item {
  border-bottom: none;
}
.facility-detail .ant-collapse-content > .ant-collapse-content-box {
  padding: 16px 0px;
}
.facility-detail
  .ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  font-size: 18px;
}

.modal-book-facility .ant-modal-title {
  text-transform: uppercase;
  font-size: 22px;
  font-weight: 700;
}
.modal-book-facility .react-tel-input .form-control {
  width: 100%;
  border-radius: 20px;
  height: 40px;
}
.modal-book-facility .react-tel-input .flag-dropdown,
.modal-book-facility .react-tel-input .flag-dropdown.open .selected-flag {
  border-radius: 20px 0px 0px 20px;
}
.modal-book-facility .input-note {
  border-radius: 20px;
}
.modal-book-facility .gender {
  margin-top: 30px;
}
.modal-book-facility .ant-select-selector {
  border-radius: 20px !important;
}
.modal-book-facility .select-date {
  width: 100%;
  border-radius: 20px;
}
.modal-book-facility .btn-cancel {
  background: #bbb;
  border-color: #bbb;
}
.dropdown-time {
  border-radius: 20px;
}
.dropdown-time .rc-virtual-list-holder-inner {
  flex-direction: row !important;
  flex-wrap: wrap;
  transform: none !important;
}
.dropdown-time .rc-virtual-list-holder-inner .ant-select-item {
  width: 33%;
}
.dropdown-time .rc-virtual-list-holder {
  max-height: 100% !important;
}
.dropdown-time .rc-virtual-list-holder > div {
  height: 100% !important;
}

.about-head .ant-divider-inner-text {
  color: var(--c-primary);
  text-transform: uppercase;
}
.about-head::before {
  width: 0% !important;
}
.about-head .ant-divider-inner-text {
  padding-left: 0px;
}
.about-specialty {
  margin-bottom: 20px;
}
.about-specialty .item {
  display: flex;
  align-items: center;
}
.about-specialty .item .title {
  padding-left: 16px;
}
.service-specialty.doctor-detail {
  margin-top: 0px;
}
.service-specialty .tabs-detail {
  margin-top: 0px;
}

.doctor-specialty .specialty {
  margin-bottom: 30px;
}
.doctor-specialty .box-cover {
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  height: 112px;
  margin-bottom: 16px;
}
.doctor-specialty .box-cover img {
  width: 100%;
  min-height: 112px;
}
.doctor-specialty .regency {
  color: #666;
  font-weight: 500;
  margin-bottom: 10px;
  margin-top: 5px;
  font-style: italic;
}
.doctor-specialty .description p {
  margin-bottom: 5px;
  color: #666;
  font-size: 14px;
}
.doctor-specialty .degree {
  font-weight: 400;
  font-size: 14px;
  color: #555;
}
.doctor-specialty .btn-view-detail {
  border-radius: 20px;
  padding: 4px 16px;
  border: 1px solid var(--c-btn-yellow);
  font-size: 13px;
  color: var(--c-btn-yellow);
}
.doctor-specialty .btn-view-detail:hover {
  border-color: var(--c-btn-yellow-hover);
  color: var(--c-btn-yellow-hover);
}
.doctor-specialty .specialty-summary {
  width: 70%;
  margin: 0px auto 30px auto;
  border-radius: 20px;
}
.doctor-specialty
  .specialty-summary
  .ant-divider-horizontal.ant-divider-with-text {
  border-color: #008180;
  margin: 0px 0px;
}
.doctor-specialty .specialty-summary .info {
  color: #555;
  font-style: italic;
  font-weight: 500;
  text-align: center;
}
.doctor-specialty .item {
  padding: 30px 0px !important;
}

.facility-detail .title-service{font-size: 15px!important; font-weight: 500!important;}
.facility-detail .service .ant-card-meta-title{height: 45px;}
