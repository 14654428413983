.page-content .content {
  max-width: 900px;
  margin: 0 auto;
  background: #f5f5f5;
  border-radius: 12px;
  padding: 64px;
  margin-bottom: 32px;
  color: #000;
  font-size: 17px;
  line-height: 32px;
}
.page-content .content p {
  margin: 0px;
}
