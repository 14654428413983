/* Doctor */
.doctor {
  box-shadow: 1.5px 1px 4px rgba(0, 0, 0, 0.15);
  border: none;
}
.doctor .ant-card-cover {
  overflow: hidden;
  height: 260px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: #fafafa;
  width: calc(100% - 0px);
  margin-left: 0px;
}
.doctor .ant-card-cover img {
  width: 100%;
  margin: 0 auto;
  border-radius: 0px;
}
.doctor .ant-card-meta-title {
  white-space: unset;
}
.doctor .title {
  font-size: 16px;
  font-weight: 700;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 22px;
  max-height: 40px;
  margin-bottom: 5px;
}
.doctor:hover .title {
  color: var(--c-hover);
}
.doctor .degree {
  font-size: 14px;
  color: #555;
  margin-bottom: 20px;
}
.doctor .sapo {
  color: #666;
  font-size: 13px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 20px;
  height: 35px;
}
.doctor .location {
  color: #777;
  position: relative;
  padding-left: 42px;
  font-size: 14px;
}
.doctor .location ion-icon {
  font-size: 20px;
  position: absolute;
  left: -4px;
}
.doctor .specialty {
  display: flex;
  flex-direction: row;
  margin: 10px 0px;
  align-items: center;
  font-size: 14px;
  color: #777;
}
.doctor .specialty .ant-avatar {
  margin-right: 10px;
}
.doctor .specialty .name {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

/* Doctor detail */
.doctor-detail {
  margin: 30px 0px;
}
.doctor-detail .box-avatar {
  width: 100%;
  max-height: 300px;
  overflow: hidden;
  border-radius: 20px;
}
.doctor-detail .box-avatar .ant-image {
  max-height: 100%;
  display: block;
}
.doctor-detail .doctor-name {
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 20px;
}
.doctor-detail .location {
  color: #777;
  position: relative;
  padding-left: 42px;
  font-size: 16px;
  margin-top: 15px;
}
.doctor-detail .specialty {
  margin: 15px 0px 0px 0px;
  font-size: 14px;
  color: #333;
}
.doctor-detail .info .title {
  margin: 10px 0px;
}
.doctor-detail .group-btn-book {margin: 20px 0px}
.doctor-detail .group-btn-book .btn-book-now {margin-right: 10px;}
.doctor-detail .tabs-detail {
  margin-top: 60px;
}
.doctor-detail .tabs-detail .ant-tabs-nav {
  margin-bottom: 0px;
}
.doctor-detail .tabs-detail .ant-tabs-nav::before {
  border: none;
}
.doctor-detail .tabs-detail .ant-tabs-content-holder {
  border-top: 1px solid var(--c-btn-yellow);
  padding: 30px 0px;
  margin-top: -1px;
}
.doctor-detail .tabs-detail .ant-tabs-tab {
  background: transparent;
  color: #999;
  font-weight: 500;
  z-index: 2;
  padding: 4px 40px !important;
  border: 0px;
}

.doctor-detail .tabs-detail .ant-tabs-tab.ant-tabs-tab-active {
  background: var(--c-btn-yellow);
  color: #fff;
}
.doctor-detail
  .tabs-detail
  .ant-tabs-tab.ant-tabs-tab-active
  .ant-tabs-tab-btn {
  color: #fff;
}
.doctor-detail .tabs-detail .ant-tabs-tab .ant-tabs-tab-btn {
  color: #999;
  font-size: 20px;
  line-height: 50px;
}
.doctor-detail .tabs-detail .about {
  color: #444;
  font-size: 15px;
}
.doctor-detail .tabs-detail .btn-book-now.right {
  margin-left: 30px;
}

.doctor-detail .tabs-detail .about .head {
  font-size: 16px;
  margin-bottom: 10px;
}
.doctor-detail .tabs-detail .about .head-sub {
  font-size: 15px;
  margin-bottom: 5px;
  margin-top: 10px;
}
.doctor-detail .tabs-detail .service {
  text-align: unset;
}
.doctor-detail .tabs-detail .service .title {
  font-weight: 700;
  font-size: 20px;
  color: #333;
  margin-bottom: 0px;
}
.doctor-detail .tabs-detail .service .time {
  color: #777;
  margin: 10px 0px;
}
.doctor-detail .tabs-detail .service .price {
  color: var(--c-hover);
  margin: 10px 0px;
}
.doctor-detail .tabs-detail .service .btn-book-calendar {
  padding: 0px 40px 0px 60px;
  height: 42px;
}
.doctor-detail .tabs-detail .service .btn-book-calendar ion-icon {
  font-size: 20px;
  position: absolute;
  left: 30px;
}
.doctor-detail .tabs-detail .service .col-info {
  border-right: 1px solid #e5e5e5;
}
.doctor-detail .tabs-detail .service .item {
  border-bottom: 1px solid #e5e5e5;
  padding: 10px 0px;
}
.doctor-detail .tabs-detail .service .group-btn-time {
  margin: 20px 0px;
}
.doctor-detail .tabs-detail .service .select-date {
  width: 300px;
}
.doctor-detail .tabs-detail .service .btn-time {
  background-color: var(--c-hover);
  border-color: var(--c-hover);
}
.doctor-detail .tabs-detail .service .btn-time[disabled] {
  border-color: #d9d9d9 !important;
  background: #f5f5f5 !important;
}
.doctor-detail .tabs-detail .service .btn-time:hover {
  background-color: #004972;
  border-color: #004972;
}
.doctor-detail .tabs-detail .service .item .header-calendar {
  font-weight: 700;
  margin-bottom: 20px;
  color: var(--c-primary);
  font-size: 18px;
}
.doctor-detail .insurance .insurance-item {
  background: #f5f5f5;
  padding: 5px 10px;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  margin: 10px 0px;
}
.doctor-detail .insurance .insurance-title {
  font-weight: 500;
  font-size: 14px;
}
.doctor-detail .insurance .insurance-sapo {
  font-size: 12px;
}
.doctor-detail .insurance .btn-view-list {
  padding: 0px;
  font-size: 12px;
}
.popover-company {
  width: 500px;
}

.modal-book-service .note {
  color: var(--c-hover);
  margin-bottom: 20px;
}
.modal-book-service .select-location {
  width: 100%;
  font-size: 15px;
}
.modal-book-service .select-date {
  width: 100%;
  border-radius: 20px;
}
.modal-book-service .react-tel-input .form-control {
  width: 100%;
  border-radius: 20px;
  height: 40px;
}
.modal-book-service .ant-select-selector {
  border-radius: 20px !important;
}
.modal-book-service .input-note {
  border-radius: 20px;
}
.modal-book-service .react-tel-input .flag-dropdown,
.modal-book-service .react-tel-input .flag-dropdown.open .selected-flag {
  border-radius: 20px 0px 0px 20px;
}
.modal-book-service .btn-cancel {
  background: #bbb;
  border-color: #bbb;
}
.box-show-more-text{margin: 10px 0px 30px 0px}
