@import url('https://fonts.googleapis.com/css?family=Montserrat:400,400i,500,700&display=swap&subset=vietnamese');

/* Defined Variable */
:root {
  --c-primary: #10375B;
  --c-header: #004972;
  --c-gray: #8c8c8c;
  --c-hover: #318fb5;
  --c-btn-yellow: #00aeac;
  --c-btn-yellow-hover: #04dad6;
  --font-main: 'Montserrat', sans-serif;
}
body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
}
/* Container page */
.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}
.d-none {
  display: none !important;
}
.d-block {
  display: block !important;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-none {
    display: none !important;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-none {
    display: none !important;
  }
  .d-md-flex {
    display: flex !important;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-none {
    display: none !important;
  }
}

/* Block header */
.block-header{ text-align: center; padding: 30px 0px}
.block-header .title{color: var(--c-header); font-size: 30px; line-height: 40px; font-weight: 700;}
.block-header .sapo{font-size: 16px; color: #777777; padding:20px 0px; max-width: 60%; margin: 0 auto}
.block-header .line{width: 60px;height: 4px;background: #00aeac;margin: 0 auto;border-radius: 3px; margin-top:10px}

/* breadcrumb */
.breadcrumb{background: #efefef; padding: 10px 0px}

/* Product */
.product{box-shadow: 1.5px 1px 4px rgba(0, 0, 0, 0.15); border:none}
.product .ant-card-cover{max-height: 255px; overflow: hidden;}
.product img{min-height: 250px; width: 99.8%;}
.product .ant-card-meta-title{white-space:unset}
.product .title{font-size: 15px; font-weight: 500; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; line-height: 22px; height: 40px;}
.product:hover .title{color: var(--c-hover);}
.product .sapo{color: #666; font-size: 14px; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; line-height: 20px; height: 40px; overflow: hidden;}
.product .price{color: var(--c-hover); margin: 15px 0px 0px 0px; font-weight: 500; font-size: 15px;}

/* image gallery */
.col-gallery .image-gallery-slides{height: 400px; display: flex; justify-content: center; overflow: hidden;}
.col-gallery .fullscreen .image-gallery-slides{height: calc(100vh - 110px);}
.col-gallery .image-gallery-thumbnails .image-gallery-thumbnails-container{text-align: left;}
.col-gallery .fullscreen .image-gallery-thumbnails .image-gallery-thumbnails-container{text-align: center;}
.col-gallery .image-gallery-thumbnail{cursor: pointer;}
.col-gallery .image-gallery-thumbnail.active, 
.col-gallery .image-gallery-thumbnail:hover,
.col-gallery .image-gallery-thumbnail:focus{border:1px solid var(--c-btn-yellow)}
.col-gallery .image-gallery-thumbnail{height: 100px; border-width: 1px; margin-right:5px}
.col-gallery .image-gallery-left-nav .image-gallery-svg, 
.col-gallery .image-gallery-right-nav .image-gallery-svg{width: 25px;}

/* specialty */
.specialty{display: flex; flex-direction: row; margin: 20px 0px; align-items: center; font-size: 16px; color: #777;}
.specialty .ant-avatar{margin-right: 10px;}

/* helper */
.border {border: 1px solid #dee2e6 !important;}
.border-0 {border: 0 !important;}
.rounded-circle {border-radius: 50% !important;}
.rounded-pill {border-radius: 50rem !important;}
.text-start {text-align: left !important;}
.text-end {text-align: right !important;}
.text-center {text-align: center !important;}
.text-decoration-none {text-decoration: none !important;}
.text-decoration-underline {text-decoration: underline !important;}
.text-decoration-line-through {text-decoration: line-through !important;}
.text-lowercase {text-transform: lowercase !important;}
.text-uppercase {text-transform: uppercase !important;}
.text-capitalize {text-transform: capitalize !important;}
.text-wrap {white-space: normal !important;}
.text-nowrap {white-space: nowrap !important;}
.text-truncate {overflow: hidden; text-overflow: ellipsis; white-space: nowrap;}
.fw-normal {font-weight: 400 !important;}
.fw-bold {font-weight: 700 !important;}
.fw-medium {font-weight: 500 !important;}
.fw-bolder {font-weight: bolder !important;}
.block-end{text-align: -webkit-right;}
.btn-global{border-color: var(--c-btn-yellow); border-radius: 20px; height: 35px; color: var(--c-btn-yellow)}
.btn-global:hover{border-color: var(--c-btn-yellow-hover); border-radius: 20px; height: 35px; color: var(--c-btn-yellow-hover)}
.btn-global.bg, .btn-global.active{background: var(--c-btn-yellow); color: #fff;}
.btn-global.bg:hover, .btn-global.active:hover{background: var(--c-btn-yellow-hover);}
.btn-link{border:1px solid; padding: 4px 8px}
.pl-0{padding-left: 0px!important;}
.pr-0{padding-right: 0px!important;}
.mb-0{margin-bottom: 0px!important;}
.w-100{width: 100%;}
.mb-20{margin-bottom: 20px;}
.mb-10{margin-bottom: 10px;}
/* select global */
.select-global.ant-select-single .ant-select-selector{border-radius: 20px; font-size: 14px;}
.body-maps{height: calc(100vh - 150px);}
.ant-back-top{bottom:100px}
.goog-te-gadget .goog-te-combo {
    margin: 4px 0;
    width: 100%;
    border-radius: 10px;
    padding: 2px 0px;
}
.grecaptcha-badge{opacity: 0;}
.translate_select{
  display:flex;background: #fff;
  position: fixed;
  right: 30px;
  bottom: 20px;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 2px 5px #ccc;
}
.translate_select .icon-flag{width: 48px; height: 48px; margin-right:10px}