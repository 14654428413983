/*pagination*/
.pagination {
  margin: 30px 0px;
}
.pagination li a {
  font-family: var(--font-main);
}

/* box search  */
.box-search {
  margin-bottom: 10px;
}
.box-search .input-keyword input {
  height: 40px;
  border-radius: 20px;
  border-color: #d9d9d9 !important;
  box-shadow: none;
  border-right: 0px;
}
.box-search .input-keyword .ant-input-search-button {
  width: 40px;
  height: 40px;
  border-radius: 20px !important;
  border-left: none;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-color: #d9d9d9 !important;
}

/* product category */
.product-category {
  padding: 30px 0px;
}
.product-category .category-header {
  font-size: 16px;
  font-weight: 700;
  color: var(--c-primary);
  text-transform: uppercase;
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 10px;
  margin-top: 12px;
  margin-bottom: 20px;
}
.product-category .category {
  border: none;
  background: #f0f0f0;
  border-radius: 10px;
  padding: 0px 20px 0px 20px;
}
.product-category .category .ant-menu-submenu-title {
  padding-left: 0px !important;
}
.product-category .category .ant-menu-title-content a {
  color: #333;
  font-weight: 500;
  font-size: 15px;
}
.product-category .category .ant-menu-item {
  padding-left: 0px !important;
}
.product-category .category .ant-menu-item a {
  font-weight: 400;
  font-size: 14px;
}
.product-category .category .ant-menu-item .anticon {
  font-size: 12px;
}
.product-category .category .ant-menu-sub {
  background: none;
}
.product-category .category .ant-menu-item-selected {
  background: none;
  border: none;
}
.product-category .category .ant-menu-item-selected::after {
  border-color: var(--c-hover);
}
.product-category .category .ant-menu-item-selected a {
  color: var(--c-hover);
}
.product-category .category a:hover {
  color: var(--c-hover);
}
.product-category .list-header {
  font-size: 24px;
  color: var(--c-primary);
  text-transform: uppercase;
  border-bottom: 1px solid #e5e5e5;
  font-weight: 700;
  margin-bottom: 30px;
  padding-bottom: 10px;
}

/* product detail */
.product-detail .breadcrumb {
  margin-bottom: 30px;
}

.product-detail .skeleton-image {
  width: 100%;
  height: 100%;
}

.product-detail .product-title {
  font-weight: 500;
  line-height: 35px;
}
.product-detail .control-rate .rate-text {
  color: #777;
  margin-left: 20px;
}
.product-detail .product-detail-price {
  color: var(--c-hover);
  margin: 10px 0px;
  font-size: 24px;
  font-weight: 700;
}
.product-detail .variant {
  margin-top: 30px;
  margin-bottom: 40px;
}
.product-detail .variant .btn-variant {
  margin: 0px 15px 0px 0px;
  border-color: #ccc;
  color: #333;
}
.product-detail .variant .btn-variant:hover,
.product-detail .variant .btn-variant.active {
  border-color: var(--c-hover);
  color: var(--c-hover);
  background: #fff;
}
.product-detail .variant .ant-row {
  margin-bottom: 30px;
}
.product-detail .variant .ant-input-group-addon {
  padding: 0px;
  border-radius: 20px;
  background: none;
}
.product-detail .variant .ant-input-group-addon:first-child {
  border-right: 0px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  padding-left: 5px;
}
.product-detail .variant .ant-input-group-addon:last-child {
  border-left: 0px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  padding-right: 5px;
}
.product-detail .variant .ant-input-group-addon .anticon {
  font-size: 15px;
  width: 30px;
  color: #555;
}
.product-detail .variant .ant-input {
  border-left: none;
  border-right: none;
  border-color: #e5e5e5 !important;
  box-shadow: none;
  text-align: center;
  height: 35px;
}
/* control button */
.product-detail .btn-add-cart {
  margin-right: 20px;
  height: 40px;
  width: 200px;
  font-size: 16px;
  padding-left: 30px;
}
.product-detail .btn-add-cart .anticon {
  position: absolute;
  left: 20px;
  font-size: 24px;
  top: 5px;
}
.product-detail .btn-add-cart:hover {
  color: #fff;
  border-color: var(--c-btn-yellow-hover);
  background: var(--c-btn-yellow-hover);
}
.product-detail .btn-buy-now {
  height: 40px;
  width: 200px;
  font-size: 16px;
}

/* Shop info */
.shop-info {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  padding: 20px 0px;
  margin: 20px 0px;
}
.shop-info .shop-name {
  font-weight: 500;
  line-height: 20px;
  font-size: 16px;
}
.shop-info .location {
  color: #777;
  position: relative;
  padding-left: 20px;
}
.shop-info .location ion-icon {
  font-size: 20px;
  position: absolute;
  left: -4px;
}
.shop-info .btn-view-shop {
  margin-top: 15px;
  font-size: 16px;
}
.shop-info .btn-view-shop .anticon {
  font-size: 18px;
}
.shop-info .info-rate .ant-descriptions-item {
  padding: 8px 0px;
}
.shop-info .info-rate .ant-descriptions-item-label {
  color: #777;
  font-weight: 500;
}
.shop-info .info-rate .ant-descriptions-item-content {
  color: var(--c-hover);
}

/* product params */
.product-param .title {
  font-size: 24px;
  color: #000;
  font-weight: 700;
  margin: 30px 0px 20px 0px;
}
.product-param .ant-descriptions-item-label {
  min-width: 200px;
  color: #777;
}

/* product sapo */
.product-sapo .title {
  font-size: 24px;
  color: #000;
  font-weight: 700;
  margin: 10px 0px 20px 0px;
}

/* product content */
.product-content {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  padding: 20px 0px;
  margin: 30px 0px;
}
.product-content .title {
  font-size: 24px;
  color: #000;
  font-weight: 700;
  margin: 10px 0px 20px 0px;
}
.product-content .ant-collapse-header {
  padding: 0px !important;
  color: #333;
  font-size: 24px;
  font-weight: 700;
}
.product-content .ant-collapse-header .ant-collapse-arrow {
  font-size: 20px !important;
}
.product-content .ant-collapse-content-box {
  padding: 20px 0px 0px 0px !important;
}

/* Cart */
.cart .cart-header {
  font-size: 16px;
  font-weight: 500;
}
.cart .cart-item {
  margin: 10px 0px;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
}
.cart .cart-item .product {
  display: flex;
  flex-direction: row;
  padding: 10px;
  box-shadow: none;
}
.cart .cart-item .product .box-cover {
  border-radius: 10px;
  overflow: hidden;
  width: 90px;
  height: 90px;
  margin-right: 16px;
  border: 1px solid #e5e5e5;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cart .cart-item .product .box-cover img {
  width: 100%;
}
.cart .cart-item .product .title {
  font-size: 16px;
  font-weight: 500;
  padding-right: 30px;
}
.cart .cart-item .ant-input-group-wrapper {
  width: 70%;
  text-align: center;
}
.cart .cart-item .ant-input-group-addon {
  padding: 0px;
  border-radius: 20px;
  background: none;
}
.cart .cart-item .ant-input-group-addon:first-child {
  border-right: 0px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  padding-left: 5px;
}
.cart .cart-item .ant-input-group-addon:last-child {
  border-left: 0px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  padding-right: 5px;
}
.cart .cart-item .ant-input-group-addon .anticon {
  font-size: 15px;
  width: 30px;
  color: #555;
}
.cart .cart-item .btn-delete {
  border-radius: 20px;
  color: #999;
}
.cart .cart-item .btn-delete:hover {
  border-radius: 20px;
  color: var(--c-btn-yellow-hover);
  border-color: var(--c-btn-yellow-hover);
}
.cart .row-total {
  margin-top: 30px;
  margin-bottom: 20px;
}
.cart .row-total .price-total {
  font-size: 26px;
  color: var(--c-btn-yellow);
  font-weight: 700;
}
.cart .group-btn {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #e5e5e5;
  padding: 20px 0px;
}
.cart .group-btn button {
  height: 40px;
}
.cart .group-btn .btn-payment {
  border-radius: 20px;
  padding: 0px 50px;
}

.page-checkout {
  padding: 30px 0px;
}
.page-checkout .col-info {
  border-right: 1px solid #e5e5e5;
}
.page-checkout .wrap-step {
  width: 80%;
  margin: 0px auto 30px auto;
}
.page-checkout .form-header {
  margin-bottom: 20px;
}
.page-checkout .react-tel-input .form-control {
  width: 100%;
  border-radius: 20px;
  height: 40px;
}
.page-checkout .react-tel-input .flag-dropdown,
.page-checkout .react-tel-input .flag-dropdown.open .selected-flag,
.page-checkout .react-tel-input .selected-flag {
  border-radius: 20px 0px 0px 20px;
}
.page-checkout .list-product .item {
  margin: 10px 0px 20px 0px;
}
.page-checkout .list-product .item .box-cover {
  border-radius: 10px;
  overflow: hidden;
  width: 70px;
  height: 70px;
  margin-right: 16px;
  border: 1px solid #e5e5e5;
  display: flex;
  justify-content: center;
  align-items: center;
  float: left;
}
.page-checkout .list-product .item .box-cover img {
  width: 100%;
}
.page-checkout .list-product .item .info .title {
  font-weight: 500;
}
.page-checkout .list-product .item .info .quantity-price {
  display: flex;
  justify-content: space-between;
  color: #333;
}
.page-checkout .list-product .item .info .quantity-price .price {
  font-weight: 500;
  color: var(--c-hover);
}
.page-checkout .discount {
  margin-bottom: 20px;
}
.page-checkout .discount .ant-input {
  border-radius: 20px 0px 0px 20px;
}
.page-checkout .discount .ant-input-search-button {
  border-radius: 0px 20px 20px 0px !important;
  background: var(--c-btn-yellow);
  border-color: var(--c-btn-yellow);
}
.page-checkout .other-price {
  display: flex;
  justify-content: space-between;
  margin: 10px 0px;
}
.page-checkout .other-price .price {
  color: var(--c-hover);
  font-weight: 500;
}
.page-checkout .order-total {
  border-top: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
  vertical-align: middle;
  padding: 20px 0px;
  margin-top: 30px;
}
.page-checkout .order-total .price-total {
  font-size: 24px;
  color: var(--c-hover);
  font-weight: 700;
}
.page-checkout .order-total span:first-child {
  font-weight: 700;
  font-size: 18px;
}
.page-checkout .group-btn {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #e5e5e5;
  padding-top: 20px;
}
.page-checkout .sumary-info {
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
}
.page-checkout .pay-method {
  margin-bottom: 30px;
  margin-top: 30px;
}
.page-checkout .pay-method-title {
  font-weight: 700;
  font-size: 15px;
  margin-bottom: 20px;
}
.page-checkout .pay-method .method {
  background: #f7f7f7;
  padding: 10px;
  border: 1px solid #e5e5e5;
  width: 100%;
  border-radius: 10px;
}
.page-checkout .pay-method .ant-space,
.page-checkout .pay-method .ant-radio-group-outline {
  width: 100%;
}
.page-checkout .pay-method .method .bank {
  margin-top: 15px;
}
.page-checkout .pay-method .logo-onepay {
  width: 90px;
  position: absolute;
  top: 0;
  right: 0;
}
.page-checkout .pay-method .ant-radio-wrapper {
  width: 100%;
}
.page-checkout .pay-method span.ant-radio + * {
  width: 100%;
}

.bank-transfer {
  width: 700px;
  margin: 30px auto 30px auto;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  padding: 20px;
  line-height: 30px;
}
.bank-transfer .price {
  font-size: 24px;
  color: var(--c-hover);
  font-weight: 700;
}
.bank-transfer .bank-info {
  margin-top: 15px;
}
.bank-transfer .btn-buy {
  padding: 10px 50px;
  margin: 30px 0px;
}

.page-checkout .payment-success .title {
  color: var(--c-hover);
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 20px;
}
.page-checkout .payment-success .box-info {
  width: 620px;
  margin: 0 auto;
  line-height: 30px;
}
