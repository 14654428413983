.handbook-category{padding:0px 0px 32px 0px}
.handbook-category .menu-left-service .category{overflow-y: auto; max-height: 500px;}
.handbook-category .menu-left-service .category::-webkit-scrollbar {width: 5px;}
.handbook-category .menu-left-service .category::-webkit-scrollbar-track {background: #f1f1f1;}
.handbook-category .menu-left-service .category::-webkit-scrollbar-thumb {background: var(--c-primary); border-radius: 3px; margin-right: 5px;}
.handbook-category .menu-left-service .category::-webkit-scrollbar-thumb:hover {background: var(--c-hover);}
.article{margin-bottom: 24px;}
.article .box-cover{border-radius: 10px; overflow: hidden; width: 100%; height: 187px; display: flex; align-items: center; background-color: #fafafa; justify-content: center;}
.article .box-cover img{min-height: 187px; width: 100%;}
.article .title{font-size: 20px; font-weight: 700;}
.article:hover .title{color: var(--c-hover);}
.article .sapo{font-size: 15px; color: #555; margin-top: 8px; margin-bottom: 0px;}
.article .util{font-size: 13px;}
.article-source{color: #999; font-style: italic;}

.article-title{font-size: 28px; color: var(--c-primary); font-weight: 700; margin: 20px 0px 20px 0px}
.article-sapo{font-style: italic; margin: 16px 0px; color: #555; font-weight: 700; background: #F3F7FD; padding:16px; border-radius: 10px;}
.article-content{font-size: 16px; line-height: 32px;}
.article-content h1{font-size: 18px; font-weight: 700;}
.article-content h2{font-size: 17px; font-weight: 700;}
.article-content h3{font-size: 16px; font-weight: 700;}
.table-of-content{margin-top: 30px; border-radius: 10px;}
.table-of-content.ant-card-type-inner .ant-card-body{padding:8px}
.table-of-content .toc{list-style: none; padding:0px}
.table-of-content .toc .toc-list{padding-left:20px}
.table-of-content .toc .toc-list-item{margin-bottom: 10px;}
.table-of-content .toc .toc-link{color: #333; line-height: 20px;}
.table-of-content .toc .is-active-li{color: #1890ff;}
.table-of-content .toc .is-active-li .toc-link{color:#1890ff}
.table-of-content .ant-card-head{border-radius: 10px 10px 0 0;}
.toc-mobile .toc-link{line-height: 24px; color: #333; }
.toc-mobile  .is-active-li{color: #1890ff;}
.toc-mobile .is-active-li .toc-link{color:#1890ff}

.handbook-tag{margin-bottom: 8px; margin-top: 8px}

.handbook-related{margin-bottom: 32px; margin-top:32px}
.handbook-related .head .ant-divider-inner-text{font-size: 20px; font-weight: 700; color: var(--c-primary); text-transform: uppercase;}
.handbook-related .item{margin-bottom: 16px;}
.handbook-related .item .box-cover{border-radius: 10px; overflow: hidden; width: 100%; height: 187px; display: flex; align-items: center; background-color: #fafafa; justify-content: center; margin-bottom: 10px;}
.handbook-related .item .box-cover img{min-height: 187px; width: 100%;}
.handbook-related .item .title{font-size: 16px; font-weight: 500;}
.handbook-related .item:hover .title{color: var(--c-hover);}

.handbook-detail .categories{background: #F3F7FD; border-radius: 10px; padding: 16px; margin-bottom: 32px;}
.handbook-detail .categories .head .ant-divider-inner-text{font-size: 20px; font-weight: 700; color: var(--c-primary); text-transform: uppercase;}
.handbook-detail .categories .cate-link{color: #333; font-weight: 500; line-height: 40px;}
.handbook-detail .categories .cate-link:hover{color: var(--c-hover);}
.handbook-detail .categories .cate-link .anticon{font-size: 14px;}
.handbook-detail .categories .list{margin: 0px 20px}
.handbook-detail .tags .ant-tag-blue{margin-bottom: 8px;}
.drawer-table-content .ant-drawer-body{padding: 16px 0px}
.drawer-table-content .ant-drawer-title{color: var(--c-primary); text-transform: uppercase; font-weight: 500;}
.btn-open-toc{position: fixed; bottom: 8px; right: 8px}
