/* Đánh giá */
.block-review .block-review-title { font-size: 24px; color: #000; font-weight: 700; margin: 10px 0px 20px 0px }
.block-review .total {margin-bottom: 20px;}
.block-review .total-rate {font-size: 35px; font-weight: 700; padding-top: 10px;}
.block-review .total-rate-text {color: #777;}
.block-review .total-star {
font-size: 25px;}
.block-review .ant-slider-handle {
  width: 0px;
  height: 0px;
  border: none;
}
.block-review .rate-statictis {
  margin: -3px 0px;
}
.block-review .rate-statictis .rate-star {
  font-size: 18px;
}
.block-review .rate-statictis .rate-star .ant-rate-star:not(:last-child) {
  margin-right: 4px;
}
.block-review .ant-slider-step {
  height: 10px;
}
.block-review .ant-slider-rail {
  height: 10px;
  border-radius: 10px;
  background-color: #ddd;
}
.block-review .ant-slider-disabled .ant-slider-track {
  height: 10px;
  border-radius: 10px;
}
.block-review .rate-statictis .num {
  padding-top: 5px;
  padding-left: 10px;
  color: #777;
}
.block-review .rate-filter button {
  margin-right: 15px;
  border-color: #dbac3a;
  color: #dbac3a;
}
.block-review .rate-filter button.bg {
  background-color: #dbac3a;
  color: #fff;
}
.block-review .rate-filter button:hover {
  border-color: #bc9647;
  color: #bc9647;
}
.block-review .rate-images {
  margin: 20px 0px;
}
.block-review .rate-images .title {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 10px;
}
.block-review .rate-images .item-img {
  cursor: pointer;
}
.block-review .rate-images .ant-image {
  border-radius: 15px;
  overflow: hidden;
}
.block-review .rate-images .item-img.mask:last-child .ant-image::before {
  content: "";
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  z-index: 1;
  top: 0;
  left: 0;
}
.block-review .rate-images .item-img .caption {
  position: absolute;
  top: 40%;
  left: 0;
  font-size: 20px;
  font-weight: 700;
  color: #fff;
  z-index: 9;
  width: 100%;
  text-align: center;
}

/* list comment */
.list-comment {
  margin: 30px 0px;
}
.list-comment .item-comment {
  margin-bottom: 20px;
  border-bottom: 1px solid #ddd;
}
.list-comment .item-comment .cus-name {
  font-weight: 500;
  color: #444;
  margin-top: 5px;
  margin-bottom: 16px
}
.list-comment .item-comment .star {
  font-size: 18px;
}
.list-comment .item-comment .star .ant-rate-star:not(:last-child) {
  margin-right: 4px;
}
.list-comment .item-comment .pleased {
  font-size: 14px;
  font-weight: 600;
  padding-left: 25px;
}
.list-comment .item-comment .util {
  color: #777;
  padding: 5px 0px;
}
.list-comment .item-comment .content {
  color: #777;
  margin-top: 8px;
}
.list-comment .item-comment .images {
  margin-top: 15px;
  margin-bottom: 20px;
}
.list-comment .item-comment .images .item-img {
  cursor: pointer;
}
.list-comment .item-comment .images .ant-image {
  border-radius: 15px;
  overflow: hidden;
}
.list-comment .item-comment .images .item-img.mask .ant-image::before {
  content: "";
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  z-index: 1;
  top: 0;
  left: 0;
}
.list-comment .item-comment .images .item-img .caption {
  position: absolute;
  top: 40%;
  left: 0;
  font-size: 20px;
  font-weight: 700;
  color: #fff;
  z-index: 9;
  width: 100%;
  text-align: center;
}
.btn-write-comment{min-width: 280px; font-size: 16px; margin: 10px 0px 30px 0px}
.wrap-write-review .ant-modal{width: 800px!important;}
.wrap-write-review .ant-modal-close{width: 110px}
.wrap-write-review .ant-rate{margin-right: 16px;}
.wrap-write-review .ant-rate-star.ant-rate-star-zero .ant-rate-star-second{color: #ccc}
.wrap-write-review .ant-rate-star .anticon{font-size: 26px; margin-left: 16px;}
.wrap-write-review .review-content{margin-top: 20px;}
.wrap-write-review .review-content .ant-input{color: #333; border-radius: 6px;}
.wrap-write-review .review-content ::placeholder { color: #777; opacity: 1;}
.wrap-write-review .upload-list-inline .ant-upload-list-item {float: left; margin-right:8px}
.wrap-write-review .review-note{font-size: 12px; margin: 20px 0px 0px 0px; font-style: italic;}
.wrap-write-review .btn-upload{border-color:#1890ff; color: #1890ff; margin-right:8px}
.wrap-write-review .upload-note{font-size: 12px; font-style: italic; color: #555;}
.wrap-write-review .form-control::placeholder { color: #777; opacity: 1;}