.box-login{width: 350px; margin: 0 auto;}
.box-login .btn-login{margin-top: 10px; height: 40px; width: 200px;}
.box-login .link-register{padding-left: 5px}
.login-success .btn-global{padding:10px 20px;}
.login-success .btn-book{padding:10px 40px;}
.box-register{ width: 500px; margin: 0 auto}
.box-register .react-tel-input .form-control,.box-login .react-tel-input .form-control{width: 100%; height: 40px; border-radius: 20px;}
.box-register .react-tel-input .flag-dropdown,.box-login .react-tel-input .flag-dropdown{border-radius: 20px 0px 0px 20px;}
.box-register .react-tel-input .flag-dropdown .selected-flag,.box-login .react-tel-input .flag-dropdown .selected-flag{border-radius: 20px 0px 0px 20px!important;}
.box-register .btn-register{width: 200px; height: 40px;}
.active-success{min-height: 100px;}

.register-agency{padding-top:50px; padding-bottom: 50px;}
.register-agency .title{font-size: 26px; font-weight: 700; color: var(--c-primary); text-align: center; margin-bottom: 20px;}
.register-agency .slogan{font-size: 16px; color: #777; text-align: center; font-weight: 500;}
.register-agency .banner img{height: 300px; margin-top: 50px}
.register-agency .register-now{font-size: 22px; font-weight: 700; color: var(--c-primary); margin-bottom: 30px}
.register-agency .register-box{border:1px solid #e5e5e5; border-radius: 20px; padding:25px}
.register-agency .ant-switch{min-width: 100px}
.register-agency .ant-switch-inner{text-align: left;}