/* Banner home */
.banner-home {
  height: 600px;
  overflow: hidden;
  position: relative;

}

.banner-home .caption {
  position: absolute;
  z-index: 99;
  background-image: linear-gradient(rgba(250, 250, 250, 0.05), rgba(250, 250, 250, 0.8), rgba(250, 250, 250, 1));
  bottom: 0;
  width: 100%;
  padding: 30px 0px 30px 0px;
}

.banner-home .caption .container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.banner-home .caption .service-name {
  font-size: 30px;
  text-transform: uppercase;
  color: #004972;
  font-weight: 700;
}

.banner-home .caption .hotline {
  font-size: 20px;
  text-transform: uppercase;
  color: #004972;
  font-weight: 700;
}

.banner-home .caption .book-now {
  background-color: #00aeac;
  color: #fff;
  text-transform: uppercase;
  border-radius: 40px;
  font-size: 18px;
  height: 60px;
  margin-right: 30px;
  line-height: 60px;
  border: none;
  padding: 0px 50px;
  font-size: 20px;
  font-weight: 700;
  margin-left: 35%;
}

.banner-home .caption .book-now:hover {
  background-color: #00DAD6;
}

.banner-home .item {
  position: relative;
  height: 600px;
}

.banner-home .slick-slider {
  height: 600px;
}

.banner-home img {
  width: 100%;
  min-height: 600px;
}

.banner-home .control-arrow {
  position: absolute;
  width: 100%;
  top: 45%;
}

.banner-home .control-arrow .btn-prev {
  float: left;
  margin-left: 40px;
}

.banner-home .control-arrow .btn-next {
  float: right;
  margin-right: 40px;
}

/* box-filter */
.box-filter {
  padding-left: 64px;
  margin-bottom: 30px;
}

.box-filter .btn-filter {
  margin: 0px 25px;
  min-width: 125px;
}

.section-doctor-online .view-more {
  margin-top: 16px;
}

/* list product */
.list-product {
  margin-bottom: 30px;
}

.view-more {
  width: 100%;
  margin-bottom: 25px;
}

.view-more a {
  font-size: 17px;
  font-weight: 500;
  color: var(--c-primary);
}

.view-more a:hover {
  color: var(--c-hover);
}

/* professional team */
.professional-team {
  overflow: hidden;
  position: relative;
  background-image: url("../../assets/images/banner-1.jpeg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  padding: 50px 0px;
  display: flex;
  justify-content: center;
}

.professional-team img {
  width: 100%;
  min-height: 350px;
  filter: blur(2px);
  -webkit-filter: blur(2px);
}

.professional-team .caption {
  background: rgba(0, 0, 0, 0.5);
  padding: 30px 100px;
  top: 0;
  text-align: center;
  width: 60%;
}

.professional-team .caption .title {
  color: #fff;
  font-size: 30px;
  font-weight: 700;
}

.professional-team .caption .sapo {
  color: #fff;
  font-size: 16px;
  padding: 20px 0px;
}

.professional-team .caption .line {
  width: 60px;
  height: 4px;
  background: var(--c-btn-yellow);
  margin: 0 auto;
  border-radius: 3px;
  margin-top: 0px;
  margin-bottom: 30px;
}

.professional-team .caption .btn-detail {
  margin-top: 20px;
  text-transform: uppercase;
  font-size: 16px;
  width: 200px;
  padding: 10px 40px;
  font-weight: 500;
}

.professional-team-list {
  margin: 30px 0px;
}

.section-doctor-spec {
  margin-top: 30px;
}

.section-doctor-spec .view-more {
  margin-top: 16px;
}

.section-shop {
  margin-bottom: 30px;
  background-image: url("../../assets/images/banner-section-shop.jpeg");
}

/* item service */
.service {
  border: none;
  text-align: center;
  box-shadow: none !important;
}

.service .ant-card-meta-title {
  height: 80px;
  white-space: normal;
}

.service:hover .title {
  color: var(--c-hover);
}

.service .cover {
  margin: 0 auto;
  border: 1px solid #efefef;
}

.service .title {
  font-size: 18px;
  font-weight: 500;
  color: #333;
  margin-bottom: 30px;
}

.service .btn-view {
  text-transform: uppercase;
  padding: 10px 50px;
  margin-top: 20px;
  font-weight: 500;
  font-size: 16px;
}

/* health facilities */
.health-facility {
  background-image: url("../../assets/images/banner-facility.jpeg");
}

/* health service */
.health-service .box-filter .btn-filter {
  margin: 0px 20px;
  min-width: 180px;
}

.health-service .list {
  margin: 50px 0px 30px 0px;
}

/* section-group-service  */
.section-group-service {
  padding: 30px 0px;
  /*background-image: linear-gradient(rgba(255, 255, 255, 0.04), rgba(255, 255, 255, 0.85), rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 1));*/
  position: relative;
  z-index: 1;
  background-color: #fafafa
}

.section-group-service .item {
  text-align: center;
}

.section-group-service .item .title {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 700;
  color: var(--c-primary);
}

.section-group-service .item:hover .title {
  color: #00a3c8;
}

.section-group-service .item .box-avatar {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  border: 1px solid #00a3c8;
  margin: 0 auto;
}

.section-group-service .item:hover .box-avatar {
  background-color: #00a3c8;
}

.section-group-service .item .box-avatar .icon {
  color: #00a3c8;
  font-size: 36px;
  line-height: 85px;
}

.section-group-service .item:hover .box-avatar .icon {
  color: #fff;
}
.section-group-service .travel-medical .box-avatar{display: flex; flex-direction: row; justify-content:center; align-items: center; }
.section-group-service .travel-medical .box-avatar .white{display: none;}
.section-group-service .travel-medical:hover .box-avatar .white{display: block;}
.section-group-service .travel-medical:hover .box-avatar .blue{display: none;}
.section-group-service .travel-medical img{width: 36px; height: 36px;}

/* section hot */
.icon-hot {
  width: 48px;
  margin-top: -30px;
  margin-left: -56px;
  margin-right: 8px
}

.section-hot .ant-carousel {
  overflow: hidden;
}

.section-hot .react-multi-carousel-list {
  margin: 0px -16px
}

.section-hot .hot-item {
  padding: 0px 16px 30px 16px;
}

.section-hot .hot-item .box-cover {
  width: 100%;
  height: 140px;
  overflow: hidden;
  border-radius: 10px;
  position: relative;
  /*display: flex;*/
  justify-content: center;
  border: 1px solid #f5f5f5
}

.section-hot .hot-item .box-cover .cover {
  width: 100%;
  min-height: 100%;
}

.section-hot .hot-item .box-cover .cover.pro {
  width: auto;
  height: 100%;
}

.section-hot .hot-item .box-cover .caption {
  position: absolute;
  top: 8px;
  left: 8px;
  padding: 2px 10px;
  background-color: #D5AD51;
  color: #fff;
  border-radius: 15px;
  font-size: 13px;
  font-weight: 500;
}

.section-hot .hot-item .title {
  font-size: 14px;
  font-weight: 700;
  line-height: 1.5;
  margin: 8px 0px 16px 0px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 40px;
  overflow: hidden;
}

.section-hot .hot-item .sapo {
  color: #444;
  margin: 10px 0px;
  font-size: 13px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 20px;
  height: 40px;
  overflow: hidden;
}

.section-hot .hot-item .sapo p {
  margin: 0px
}

.section-hot .hot-item .view-detail {
  color: #318fb5;
}

.section-hot .hot-item .view-detail:hover {
  color: #00a3c8;
}

.section-hot .wrap-carousel {
  position: relative;
}

.section-hot .btn-nav {
  font-size: 24px;
  color: #888;
  position: absolute;
  top: 45%;
  z-index: 99;
  cursor: pointer;
}

.section-hot .btn-nav:hover {
  color: #00a3c8;
}

.section-hot .btn-nav.nav-left {
  left: -50px
}

.section-hot .btn-nav.nav-right {
  right: -50px
}

.section-hot .custom-dot {
  bottom: -3px
}

.section-hot .custom-dot li button {
  background-color: #aaa;
  border: none;
  width: 10px;
  height: 10px;
}

.section-hot .custom-dot li.react-multi-carousel-dot--active button {
  background-color: #00a3c8;
}

/* f0 at home */
.f0-at-home .list {
  margin-bottom: 50px;
}

.f0-at-home .list .item .box-cover {
  display: flex;
  justify-content: center;
  overflow: hidden;
  height: 270px;
  border-radius: 10px;
}

.f0-at-home .list .item .box-cover .cover {
  width: 100%;
  min-height: 100%;
}

.f0-at-home .list .item .title {
  font-size: 15px;
  font-weight: 700;
  margin: 16px 0px
}

.f0-at-home .list .item .sapo {
  color: #555;
  margin-bottom: 30px;
  height: 90px;
  overflow: hidden;
}

.f0-at-home .list .item .btn-book {
  padding: 8px 20px;
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
  display: block;
  max-width: 280px;
  margin: 0 auto;
}

.home-slogan {
  position: absolute;
  width: 100%;
  bottom: 20%;
  left: 0;
  text-align: center;
}

.home-slogan .bg {
  background-color: rgba(255, 255, 255, 0.7);
  padding: 30px 20px 10px 20px;
  border-radius: 30px;
}

.animate-charcter {
  text-transform: uppercase;
  background-image: linear-gradient(-225deg,
      #1A3657 0%,
      #057bbf 29%,
      #18bdba 67%,
      #075b5a 100%);
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 4s linear infinite;
  display: inline-block;
  font-size: 40px;
  font-weight: 700;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}


.modal-select-service .item {
  text-align: center;
  margin-bottom: 16px;
}

.modal-select-service .item .title {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 700;
  color: var(--c-primary);
}

.modal-select-service .item .box-avatar {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  border: 1px solid #00a3c8;
  margin: 0 auto;
}

.modal-select-service .item .box-avatar .icon {
  color: #00a3c8;
  font-size: 36px;
  line-height: 85px;
}

.modal-select-service .item:hover .box-avatar {
  background-color: #00a3c8;
}

.modal-select-service .item:hover .icon {
  color: #fff;
}

.modal-select-service .item:hover .title {
  color: #00a3c8;
}

.list-pharma .ant-card-body{padding-bottom: 0px;}
.list-pharma .service .ant-card-meta-title{height: 30px;}